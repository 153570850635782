import { render, staticRenderFns } from "./RelatedSamples.vue?vue&type=template&id=a55c358e&scoped=true"
import script from "./RelatedSamples.vue?vue&type=script&lang=js"
export * from "./RelatedSamples.vue?vue&type=script&lang=js"
import style0 from "./RelatedSamples.vue?vue&type=style&index=0&id=a55c358e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a55c358e",
  null
  
)

export default component.exports