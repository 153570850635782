<template>
    <!-- 拍摄完成/拍摄未完成 -->
    <div v-if="data.flag" class="mask">
        <div class="mask-main">
            <div class="mask-header">
                <div class="mask-left">关联展示图</div>
                <div @click="handleconfirm">×</div>
            </div>
            <div class="mask-table">
                <div class="search_nav">
                    <!-- 搜索框 -->
                    <el-input v-model="keyword" class="search_inp" style="width: 13.542vw;"
                        placeholder="请输入编号/创建人"></el-input>
                    <!-- 拍摄主题下拉框 -->
                    <!-- <el-select :popper-append-to-body="false" v-model="theme_id" placeholder="请选择拍摄主题" class="clothing_size"
                        clearable>
                        <el-option v-for="item in themelist" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select> -->
                    <el-cascader v-model="themetypevalue" :options="themelist" :popper-append-to-body="false"
                        class="clothing_size2" placeholder="请选择标准片类型"
                        :props="{ multiple: true, value: 'id', label: 'name' }"></el-cascader>
                    <!-- 是否启用下拉框 -->
                    <el-select :popper-append-to-body="false" v-model="status" placeholder="请选择是否启用"
                        class="clothing_size" clearable>
                        <el-option v-for="item in statuslist" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <!-- 日期时间选择器 -->
                    <el-date-picker v-model="timelist" type="daterange" range-separator="-" start-placeholder="最新修改开始时间"
                        end-placeholder="最新修改结束时间">
                    </el-date-picker>
                    <!-- 查询按钮 -->
                    <el-button class="searchBtn" type="primary" @click="spreadStylelist">查询</el-button>
                    <!-- 重置 -->
                    <el-button class="searchBtn clearbtn" type="primary" @click="handleClear">重置</el-button>
                </div>
                <div class="xian"></div>
                <div class="tabledata">
                    <div class="tab">
                        <div class="tab-main">
                            <div v-for="item in activeList" :key="item.id" @click="handleTab(item.id)"
                                :class="['tab-item', item.id == activeName ? 'tab-active' : '']">
                                {{ item.name }}({{ item.count }})
                            </div>
                        </div>
                        <div class="nav-right">
                            <button class="btn-color" @click="handlePushs('1')">关联</button>
                        </div>
                    </div>

                    <el-table id="filei" class="center_table" ref="multipleTable" height="29.688vw"
                        :data="tableData.list" tooltip-effect="dark" style="width: 100%" border :header-cell-style="{
                            width: 81.823 + 'vw',
                            height: 5.185 + 'vh',
                            background: '#F9F9F9',
                            fontSize: 0.833 + 'vw',
                            textAlign: 'center',
                        }" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" min-width="5.3px" align="center">
                        </el-table-column>
                        <el-table-column align="center" prop="id" label="编号" min-width="15.3px">
                            <template slot-scope="scope">
                                <span class="aFu_span">{{ scope.row.bianhao }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="image" label="展示图" show-overflow-tooltip min-width="24px">
                            <template slot-scope="scope">
                                <div class="aFu" @contextmenu="disableRightClick">
                                    <!-- <img style="max-width: 100%;max-height: 3.704vh;" v-for="item in scope.row.images" :key="item"
                                    :src="imgurl + item"> -->
                                    <el-image style="max-width: 4.167vw;max-height: 3.704vh;"
                                        :src="imgurl + scope.row.image" @click="bigimgclick(imgurl + scope.row.image)">
                                    </el-image>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" align="center" label="展示名称" show-overflow-tooltip
                            min-width="14.6px"></el-table-column>
                        <el-table-column align="center" prop="type" label="标准片类型" show-overflow-tooltip
                            min-width="15.9px"></el-table-column>
                        <el-table-column align="center" prop="sex" label="适用性别" show-overflow-tooltip
                            min-width="13.8px"></el-table-column>
                        <el-table-column align="center" prop="size" label="衣码" show-overflow-tooltip
                            min-width="13.8px"></el-table-column>
                        <el-table-column align="center" label="是否启用" min-width="11.8px">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.status"
                                    @change="handlePushs('2', scope.row.id, scope.row.status)" active-color="#13ce66"
                                    inactive-color="#D7D6D6">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="src" label="来源" show-overflow-tooltip
                            min-width="13.8px"></el-table-column>
                        <el-table-column label="最新修改时间" prop="updatetime" align="center" min-width="13.8px">
                        </el-table-column>
                        <el-table-column label="修改人" prop="realname" align="center" min-width="13.8px">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="footer-page">
                    <span>共{{ tableData.total_count }}条</span>
                    <el-pagination popper-class="paging" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" background :current-page="page"
                        :page-sizes="[10, 20, 30, 40]" :page-size="count" layout="sizes, prev, pager, next, jumper"
                        :total="tableData.total_count" :pager-count="5">
                    </el-pagination>
                </div>
            </div>
            <MaskPack :data="pushObj" @childRef="handlePushsCall"></MaskPack>
            <!-- 预览 -->
            <ImgPreview :data="imgpreviewObj" @imgpreviewCall="imgpreviewCall"></ImgPreview>
        </div>
    </div>
</template>

<script>
export default {
    name: "capture",
    props: ['data'],
    data() {
        return {
            page: 1,
            count: 10,
            keyword: "",
            theme_id: "",
            status: "",
            timelist: null,
            themelist: [],
            themetypevalue: [],
            statuslist: [
                {
                    id: 0,
                    name: "否"
                },
                {
                    id: 1,
                    name: "是"
                },
            ],
            multipleSelection: [],
            //表格切换
            activeName: -1,
            activeList: [
                {
                    id: -1,
                    name: "全部",
                    count: 0
                }
            ],

            pushObj: {
                flag: false,//是否显示
                title: "移除标准片",//标题
                text: "确认要批量移除标准片吗？",//内容
                btns: "确定",//确定按钮
                btnq: "关闭",//取消按钮
            },

            shopname: '',
            imgpreviewObj: {
                flag: false,
                src: '',
                shopname: '',
            },
            tableData: {
                list: [],
                total_count: 0
            },
        }
    },
    mounted() {
        this.indexGetinfo()
    },
    methods: {
        handleClear() {
            this.keyword = ''
            this.theme_id = ''
            this.status = ''
            this.timelist = []
            this.themetypevalue = []
            this.spreadStylelist()
        },
        //得到当前账号所关联的店铺信息
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo();
            if (res.code == 1) {
                this.shopname = res.data.shopname
            }
        },
        //tab切换
        handleTab(id) {
            this.activeName = id;
            this.spreadStylelist()
        },
        // 阻止默认右键菜单的显示
        disableRightClick(event) {
            event.preventDefault();
            return false
        },
        bigimgclick(url) {
            this.imgpreviewObj.flag = true
            this.imgpreviewObj.src = url
            this.imgpreviewObj.shopname = this.shopname
        },
        imgpreviewCall() {
            this.imgpreviewObj.flag = false
        },
        // 保存
        handleconfirm() {
            let obj = {
                flag: false,
                submitFlag: true,
            }
            this.$emit('relatedSamplesCall', obj)
        },
        handlePushs(type, id, status) {
            if (type == 1) {
                if (this.multipleSelection.length == 0) {
                    this.$message.warning('请选择需要关联的样片')
                    return false
                } else {
                    let length = this.multipleSelection.split(',')
                    if (length.length == 1) {

                    } else if (length.length > 1) {
                        this.$message.warning('只能关联一个样片！')
                        return false
                    }
                }
                this.pushObj = {
                    flag: true,//是否显示
                    title: "关联样片",//标题
                    text: "确认要关联该样片吗？",//内容
                    btns: "确定",//确定按钮
                    btnq: "关闭",//取消按钮
                    type: type,
                }
            } else if (type == 2) {
                if (status) {
                    this.pushObj = {
                        flag: true,//是否显示
                        title: "启用",//标题
                        text: "确认要启用该样片吗？",//内容
                        btns: "确定",//确定按钮
                        btnq: "关闭",//取消按钮
                        type: type,
                        status: status ? 1 : 0,
                        id: id
                    }
                } else {
                    this.pushObj = {
                        flag: true,//是否显示
                        title: "禁用",//标题
                        text: "确认要禁用该样片吗？",//内容
                        btns: "确定",//确定按钮
                        btnq: "关闭",//取消按钮
                        type: type,
                        status: status ? 1 : 0,
                        id: id
                    }
                }
            }
        },
        async handlePushsCall(obj) {
            if (obj.submitFlag) {
                if (this.pushObj.type == 1) {
                    let res = await this.$api.spreadSetconfig({
                        style_id: this.multipleSelection
                    })
                    if (res.code == 1) {
                        this.$message.success('成功关联样片');
                        this.pushObj.flag = false
                    }

                } else if (this.pushObj.type == 2) {
                    let res = await this.$api.spreadStylestatus({
                        id: this.pushObj.id,
                        status: this.pushObj.status
                    })
                    if (res.code == 1) {
                        this.spreadStylelist()
                        this.pushObj.flag = false
                    }
                }

            } else {
                this.pushObj.flag = false;
                if (this.pushObj.type == 2) {
                    this.tableData.list.forEach(item => {
                        if (item.id == this.pushObj.id) {
                            item.status = !item.status;
                        }
                    })
                }
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = []
            val.forEach(element => {
                this.multipleSelection.push(element.id)
            });
            this.multipleSelection = JSON.parse(JSON.stringify(this.multipleSelection)) + ""
            //  console.log(this.multipleSelection)
        },
        handleSizeChange(val) {
            this.count = val
            this.spreadStylelist()
        },
        //查看分页当前页数
        handleCurrentChange(e) {
            this.page = e
            this.spreadStylelist()
        },
        // 类型管理主页面数据（得到所有类型）
        async typeIndex() {
            let res = await this.$api.typeIndex();
            if (res.code == 1) {
                let arr = [];
                res.data = res.data.filter((e) => {
                    return e.status == 1;
                });
                res.data.forEach((element) => {
                    if (element.sec && element.sec.length > 0) {
                        element.children = element.sec;
                        element.sec = element.sec.filter((e) => {
                            return e.status == 1;
                        });
                        element.sec.forEach((item) => {
                            if (item.third && item.third.length > 0) {
                                item.third = item.third.filter((e) => {
                                    return e.status == 1;
                                });
                            }
                        });

                        element.children.forEach((item) => {
                            if (item.third && item.third.length > 0) {
                                item.children = item.third;
                            }
                        });
                    }
                });
                arr = res.data;
                this.themelist = arr;
            }
        },
        async spreadStylelist() {
            let themetypevaluearr = []
            this.themetypevalue.forEach(item => {
                if (item.length == 3) {
                    themetypevaluearr.push(item[2])
                }
            })
            let res = await this.$api.spreadStylelist({
                page: this.page,
                count: this.count,
                keyword: this.keyword,
                theme_id: JSON.parse(JSON.stringify(themetypevaluearr)) + '',
                status: this.status,
                stime: (this.timelist != null && this.timelist.length > 0) ? Date.parse(this.timelist[0]) / 1000 : "",
                etime: (this.timelist != null && this.timelist.length > 0) ? Date.parse(this.timelist[1]) / 1000 : "",
            })
            if (res.code == 1) {
                this.tableData = res.data

                this.tableData.list.forEach(item => {
                    item.status = item.status == 1 ? true : false
                    item.updatetime = this.$FilDate.filDate(item.updatetime * 1000)
                })
            }
        }
    },
    watch: {
        data: {
            deep: true,
            handler(newvalue, oldValue) {
                this.typeIndex()
                this.spreadStylelist()
            }
        },

    }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
@import url("@/assets/css/reservation/telement.less");

.mask .mask-main {
    width: 69.688vw;
    height: auto;
    padding: 0;

    .mask-header {
        width: 100%;
        height: 3.646vw;
        background-color: #0f5df9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 1.296vh 0.521vw 0 0;
        padding: 0 1.563vw;

        div {
            font-family: MicrosoftYaHei-Bold;
            font-size: 1.875vw;
            letter-spacing: 0.047vw;
            color: #ffffff;
            cursor: pointer;
        }

        .mask-left {
            font-size: 0.885vw;
            cursor: default;
        }
    }

    .mask-table {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;

        .search_nav {
            width: 100%;
            height: 2.208vw;
            padding: 0 0.938vw;

            .search_inp {
                /deep/.el-input__inner {
                    border: 0.052vw solid #DCDFE6;
                }
            }

            .clearbtn {
                color: #2c72ff;
                border: solid .0521vw #2c72ff;
                background-color: #ffffff;
            }
        }

        .xian {
            width: 100%;
            height: 0.521vw;
            background-color: #ededed;
        }

        .tabledata {
            width: 100%;
            padding: 0 0.938vw;

            /deep/.el-table__body-wrapper {
                height: calc(29.688vw - 5.185vh) !important;
                max-height: calc(29.688vw - 5.185vh) !important;
            }
        }
    }

    .footer-page {
        width: 100%;
        height: 4.3229vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.0417vw;
    }
}

/deep/.el-table__body-wrapper {
    max-height: 48vh;
}

.mask-foot {
    position: absolute;
    bottom: 3vw !important;
    left: 50%;
}

.search_nav {
    padding: 0 0 2.222vh;
}

.search_nav .search_inp {
    margin-left: 0;
}

.search_nav {
    .clothing_size {
        border: 0.052vw solid #DCDFE6;

        /deep/.el-select-dropdown {
            top: 15.778vh !important;
        }
    }
}

.negative_upload {
    border: 0.052vw solid #DCDFE6;

    /deep/.el-select-dropdown {
        top: 21.778vh !important;
    }
}

.aFu {
    /deep/img {
        max-width: 4.167vw;
        max-height: 3.704vh;
    }
}
</style>