<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 添加订单 -->
    <div class="mask-main">
      <div class="mask-content">
        <div class="mask-hea">
          <div class="mask-hea_left">
            <div class="mask-line"></div>
            标准片全览二维码
          </div>
          <div class="mask-hea_right">
            <i class="el-icon-close" @click="handleRefund"></i>
          </div>
        </div>
        <div class="mask-er">
          <img :src="qrcode" alt="" />
        </div>
        <div class="mask-hea">
          <div class="mask-hea_left">
            <div class="mask-line"></div>
            二维码有效期
          </div>
        </div>
        <div class="flex-center">
          <el-radio v-model="radio" :label="1">永久有效</el-radio>
          <el-radio v-model="radio" :label="2">自定义有效期</el-radio>
        </div>
        <div class="mask-times" v-if="radio == 1">
          <el-date-picker v-model="times2" :disabled="true" type="date" placeholder="暂不可填写">
          </el-date-picker>
        </div>
        <div class="mask-times" v-if="radio == 2">
          <el-date-picker v-model="times" type="date" value-format="timestamp" placeholder="请选择有效时间范围">
          </el-date-picker>
        </div>
      </div>
      <div class="mask-footer">
        <!-- <div class="mask-footer-btn mask-footer-btn-qu" @click="handleRefund">
          取消
        </div> -->
        <div class="mask-footer-btn" @click="handleSubmit">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Share",
  props: ["dataVal"],
  data() {
    return {
      radio: 1,
      radio2: 1,
      times: "",
      times2: "",
      qrcode: "",
      copyqrcode:"",
      expiretime: "",
      urls: this.imgurl,
      index: 1
    };
  },
  mounted() { },
  methods: {
    //取消弹窗
    handleRefund() {
      this.index = this.radio2;
      let data = {
        flag: false,
        submitFlag: false,
      };
      this.radio = this.radio2;
      this.times = this.expiretime;
      this.$emit("shareCell", data);
    },
    handleSubmit() {
      this.indexSetsharecode();
      let data = {
        flag: false,
        submitFlag: false,
      };
      
      this.clipboardImg(this.copyqrcode)
      this.$emit("shareCell", data);
    },
    /* 复制图片 */
    async clipboardImg(url) {
      try {
        const data = await fetch(url);
        const blob = await data.blob();
        await navigator.clipboard.write([
          new window.ClipboardItem({
            [blob.type]: blob
          })
        ]);
        this.$message({
          type: 'success',
          message: '复制成功'
        });
      } catch (err) {
        this.$message({
          type: 'warning',
          message: '复制失败'
        });
      }
    },
    async indexSetsharecode() {
      if (this.index != 1) {
        let expiretime = "";
        if (this.radio == 2) {
          expiretime = this.times / 1000;
        }
        let res = await this.$api.indexSetsharecode({
          expiretime: expiretime,
        });
        if (res.code == 1) {
          this.qrcode = res.data.qrcode;
          this.copyqrcode = res.data.copyqrcode;
          this.index = 2;
          if (res.data.expiretime) {
            this.radio = 2;
            this.times = res.data.expiretime * 1000;
            this.expiretime = res.data.expiretime * 1000;
          } else {
            this.radio = 1;
            this.times = "";
          }
        }
      } else {
        let res = await this.$api.indexSetsharecode();
        if (res.code == 1) {
          this.qrcode = res.data.qrcode;
          this.copyqrcode = res.data.copyqrcode;
          this.index = 2;
          if (res.data.expiretime) {
            this.radio = 2;
            this.radi2 = 2;
            this.times = res.data.expiretime * 1000;
            this.expiretime = res.data.expiretime * 1000;
          } else {
            this.radio = 1;
            this.radio2 = 1;
            this.times = "";
          }
        }
      }
    },
  },
  watch: {
    dataVal: {
      handler(newValue, oldValue) {
        this.indexSetsharecode();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask {
  .mask-main {
    width: 375px;
    height: 612px;
    border-radius: 16px;
    background: #f2f2f7;
    padding: 0;
    overflow: hidden;
    position: relative;

    .mask-content {
      padding: 20px 22px;

      .mask-hea {
        font-size: 12px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;

        .mask-hea_left {
          display: flex;
          align-items: center;

          .mask-line {
            width: 4px;
            height: 15px;
            border-radius: 5px;
            background: #007aff;
            margin-right: 5px;
          }
        }

        .mask-hea_right {
          cursor: pointer;

          .el-icon-close {
            font-size: 1.0667vw;
          }
        }
      }

      .mask-er {
        width: 100%;
        margin-bottom: 22px;
        padding: 10px;
        box-sizing: border-box;
        background-color: #fff;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .flex-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
      }

      /deep/.mask-times {
        margin-top: 13px;

        .el-date-editor {
          border: none;
        }

        .el-input {
          width: 100% !important;
          font-size: 12px;

          .el-input--prefix .el-input__inner {
            padding-left: 30px;
          }

          .el-input__inner {
            padding: 0 30px;
            box-sizing: border-box;
            height: 40px;
            line-height: 40px;
            font-size: 12px;
          }
        }
      }
    }

    .mask-footer {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;

      .mask-footer-btn {
        width: 100%;
        height: 50px;
        color: #fff;
        background: #007aff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        cursor: pointer;
      }

      .mask-footer-btn-qu {
        border-right: 1px solid #fff;
        // background: #1878df;
      }
    }
  }
}
</style>