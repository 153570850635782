<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 查看道具链接 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    查看道具链接
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <el-table class="center_table" ref="multipleTable" :data="authArr" tooltip-effect="dark"
                    style="width: 100%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.729 + 'vw',
                        textAlign: 'center',

                    }" @selection-change="handleSelectionChange">
                    <el-table-column align="center" prop="id" type="index" label="序号" min-width="14.3px">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="道具名称" min-width="16.8px">
                    </el-table-column>
                    <el-table-column align="center" prop="link" label="道具链接" min-width="38.8px">
                    </el-table-column>
                    <el-table-column align="center" prop="createtime" label="创建时间" min-width="15.7px">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "proplink",
    props: ["data"],
    data() {
        return {
            authArr: []
        };
    },
    mounted() { },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                submitFlag: false
            }
            this.$emit("proplinkCall", obj);
        },
        handleSubmit() {
            let obj = {
                flag: false,
                submitFlag: true,
            }
            this.$emit("proplinkCall", obj);
        },
        handleSelectionChange(val) {

        },
    },
    watch: {
        data: {
            deep: true,
            handler(newvalue, oldvalue) {
                this.authArr = newvalue.authArr
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");

.order-mask .mask-main {
    width: 44.792vw;
    box-sizing: border-box;
}

.aFu_span {
    color: #2C72FF;
    cursor: pointer;
}

.aFu_span img {
    max-width: 15.729vw;
    max-height: 9.259vh;
    height: auto;
    width: auto;
    display: block;
}

/deep/.table {
    padding-top: 1.852vh;

    textarea {
        height: 33.333vh;
        resize: none;
    }
}

// /deep/.el-table__empty-text {
//     line-height: 5.555555555555556vh;
// }</style>