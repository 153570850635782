<template>
    <div class="order-mask" v-if="data.flag">
        <!-- 上传底片 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div class="mask-title">
                    <div></div>
                    查看处理结果
                </div>
                <i @click="handleRefund" class="el-icon-close"></i>
            </div>
            <div class="table">
                <el-table class="center_table" ref="multipleTable" :data="authArr" tooltip-effect="dark"
                    style="width: 100%" border :header-cell-style="{
                        width: 81.823 + 'vw',
                        height: 5.185 + 'vh',
                        background: '#F9F9F9',
                        fontSize: 0.729 + 'vw',
                        textAlign: 'center',
                    }">
                    <el-table-column align="center" type="index" label="序号" min-width="5.5px">
                    </el-table-column>
                    <el-table-column align="center" prop="adminname" label="处理人" min-width="8.9px">
                    </el-table-column>
                    <el-table-column align="center" prop="createtime" label="处理时间" min-width="11.7px">
                    </el-table-column>
                    <el-table-column align="center" prop="status" label="处理状态" min-width="11px">
                    </el-table-column>
                    <el-table-column align="center" prop="info" label="进展说明" min-width="22.6px">
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="附件名称" min-width="15px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.attachimages" class="aFu_span" @click="handleSee(scope.row)">
                                查看附件
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 底部分页 -->
            <!-- <div class="footer-page">
                <span>共400条</span>
                <el-pagination popper-class="paging" @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                    layout="sizes, prev, pager, next, jumper" :total="400" :pager-count="5">
                </el-pagination>
            </div> -->
        </div>
        <!-- 查看附件 -->
        <ViewAttachments2 :dataValue="see" @attaCell="attaCell"></ViewAttachments2>
    </div>
</template>

<script>
//查看附件
import ViewAttachments2 from '@/components/management/evaluation/ViewAttachments.vue';
export default {
    name: "ViewAttachments",
    props: ["data"],
    data() {
        return {
            textarea: "",
            //角色权限列表
            authArr: [
                {
                    id: 1,
                    name: "首页",
                    flag: false
                },
                {
                    id: 2,
                    name: "数据看板",
                    flag: false
                },
                {
                    id: 3,
                    name: "统计中心",
                    flag: false
                },
                {
                    id: 4,
                    name: "登记日志",
                    flag: false
                },
            ],
            see: {
                flag: false,
                name: "查看附件"
            },
            statustype: [
                {
                    value: 0,
                    label: "未处理"
                }, {
                    value: 1,
                    label: "处理中"
                }, {
                    value: 2,
                    label: "处理完成"
                }

            ],
        }
    },
    mounted() { },
    methods: {
        //取消弹窗
        handleRefund() {
            let obj = {
                flag: false,
                typeText: ""
            }
            this.$emit("processCell", obj);
        },
        handleSubmit() {
            this.handleRefund();
        },
        handleSee(data) {
            this.see.flag = true;
            this.see.obj = data
        },
        attaCell(obj) {
            this.see.flag = obj.flag
        },

    },
    components: {
        ViewAttachments2
    },
    watch: {
        data: {
            deep: true,
            handler(newVal, oldVal) {
                this.authArr = newVal.result
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.less");

.order-mask .mask-main {
    width: 39.115vw;
    box-sizing: border-box;
}

.order-mask {
    z-index: 98;
}

.aFu_span {
    color: #2C72FF;
    cursor: pointer;
}

/deep/.table {
    padding-top: 1.852vh;

    textarea {
        height: 33.333vh;
        resize: none;
    }
}
</style>